<template>
  <div class="main">
    <Header :ticket="true" :loginBtn="true" :link="'hide'"></Header>
    <MainBanner></MainBanner>
    <SlideNotice></SlideNotice>
    <ul class="d-flex flex-nowrap p-3 gap-2 fs-px-12 w-100 overflow-auto">
      <li class="bg-main fw-bold rounded-3 h-px-50 p-3 text-center text-nowrap">
        <router-link to="/deal/list/hot" class="w-100 h-100 flex-center-center lh-sm">HOT<br>DEALS</router-link>
      </li>
      <li class="bg-main fw-bold rounded-3 h-px-50 p-3 text-center text-nowrap">
        <router-link to="/deal/list/open/new" class="w-100 h-100 flex-center-center lh-sm py-2">NEW<br>DEALS</router-link>
      </li>
      <li class="bg-main fw-bold rounded-3 h-px-50 p-3 text-center text-nowrap">
        <router-link to="/deal/list/open/almost" class="w-100 h-100 flex-center-center lh-sm py-2">ALMOST<br>SOLD OUT</router-link>
      </li>
      <li class="bg-main fw-bold rounded-3 h-px-50 p-3 text-center text-nowrap">
        <router-link to="/deal/list/closed" class="w-100 h-100 flex-center-center lh-sm py-2">CLOSED<br>DEALS</router-link>
      </li>
      <li class="bg-main fw-bold rounded-3 h-px-50 p-3 text-center text-nowrap">
        <router-link to="/serviceguideEn" class="w-100 h-100 flex-center-center lh-sm py-2">SERVICE<br>GUIDE</router-link>
      </li>
      <li class="bg-main fw-bold rounded-3 h-px-50 p-3 text-center text-nowrap">
        <router-link to="/notice" class="w-100 h-100 flex-center-center lh-sm py-2">NOTICE &<br>EVENT</router-link>
      </li>
    </ul>
    <MainList></MainList>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/common/Header.vue'
import MainBanner from '@/components/main/MainBanner.vue'
import SlideNotice from '@/components/main/SlideNotice.vue'
import MainList from '@/components/main/MainList.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");

export default {
  name: 'Home',
  components: {
    Header, SlideNotice, MainBanner, MainList, Footer
  },
  data(){
    return{
      login : this.$store.state.login,
      
    }
  },
  methods : {
  },
  mounted(){
    
  }
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar { 
  display: none !important; // 윈도우 크롬 등
}
</style>