<template>
  <div class="main">
    <h6 class="text-white mx-3 mb-3">Closed Deals Announcement</h6>
    <ul class="d-flex px-3 w-100 flex-nowrap overflow-auto text-nowrap gap-2 children-w-80"  v-if="closedList.length > 0">
      <CloseListItem v-for="(item, index) in closedList.slice(0,5)" :key="index" :item="item" :index="index" class="item" />
      <li class="bg-yellow-1 rounded-2 text-center flex-center-center flex-column px-3" @click="$router.push('/deal/list/closed')">
        <i class="far fa-arrow-right fa-lg mb-2"></i>
        <small class="fw-bold">More</small>
      </li>
    </ul>

    <!-- get dashboard -->
    <h6 class="text-white mx-3 mt-4 mb-2" >HOT DEALS</h6>
    <List :list="hotList" :hot_deal="true"></List>

    <!-- get deallist -->
    <h6 class="text-white mx-3 mt-4 mb-2" >OPEN DEALS</h6>
    <List :list="openList" :horizontal="'/deal/list/open'" class="mb-4"></List>
    
  </div>
</template>

<script>
// @ is an alias to /src
import CloseListItem from '@/components/deal/CloseListItem.vue'
import Header from '@/components/common/Header.vue'
import List from '@/components/deal/List.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");

export default {
  name: 'Home',
  components: {
    CloseListItem,
    Header, List, Footer
  },
  props:{
    list:{
        type:Array
    },
  },
  data(){
    return{
      hotList : [],
      openList:[],
      
      closedList:[],
    }
  },
  mounted(){
    this.GetDealHotList();
    this.GetCloseList();
    this.GetDealList();
  },
  methods:{
    GetCloseList(){
      this.$http.post('/member/main/GetCloseDealList',{}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.closedList = body.list;
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
      )
    },
    
    GetDealHotList(){
      this.$http.post('/member/main/GetHotDealList',{}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.hotList = body.list;
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
      )
    },
    GetDealList(){

      this.$http.post('/member/main/GetOpenDealList',{}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.openList = body.list;
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
      )
    }
  }

}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar { 
  display: none !important; // 윈도우 크롬 등
}

.children-w-80{
  .item{
    width: 80% !important;
    min-width: 250px;
  }
}
</style>