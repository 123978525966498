<template>
    <li class="rounded-1 bg-white text-center overflow-hidden item d-flex flex-column" :class="{'rotate_border':hot_deal}">
        <div class="custom-info p-2 py-3">
            <h6 class="mb-2 fw-bold"  @click="goTodetail">{{item.title}}</h6>

            <!-- 유저 랜딩으로 이동할 때 유저 아이디 -->
            <div class="seller fs-px-12 mb-2 flex-center-center gap-1" @click="GotoSeller(item)">    

                <b-avatar variant="info" :src="item.profile" class="avatar" style="width:20px; height:20px" :class="{'bg-yellow-1':item.profile===''||!item.profile}"></b-avatar>
                {{item.owner}}
                <i class="fas fa-check-circle text-burgundy-1" v-if="item.type==='A'"></i>
            </div>
            <div class="coin-info"  @click="goTodetail">
                <img :src="require(`@/assets/img/icon/color/${item.coin.toLowerCase()}.svg`)" alt="coin" class="rounded-circle">
                <div class="d-flex flex-column justify-content-between align-items-center">
                    <p><b class="fs-6">{{ item.price.toLocaleString() }}</b> <small>{{ item.coin }}</small></p>
                    <small class="text-muted fs-px-12">(= ${{item.total_price.toLocaleString()}})
                    </small>
                </div>
            </div>
            <div class="flex-between-center small fs-px-10 mb-1">
                <span>PROGRESS</span>
                <span>{{CheckPercentBar(item)}}% ({{item.count}}/{{item.total}})</span>
            </div>
            <div class="progress bg-white">
                <div class="progress-bar bg-danger" :style="{width:`${CheckPercentBar(item)}%`}"></div>
            </div>
        </div>
        <div class="ticket-info p-2 bg-yellow-5 fs-px-10 text-start d-flex flex-column flex-grow-1">
            <div class="flex-grow-1">
                Ticket Price: <b>{{item.d_price}} {{item.deal_coin}}(=$ {{(item.d_price * item.deal_price).toLocaleString()}}) / Ticket</b><br>
                Total No. of Ticket: <b>{{item.total}}</b>
            </div>
            <div class="flex-between-center mt-2">
                <i class="cursor-pointer fa-heart text-burgundy-1 fs-2" :class="{'fas':item.wishList,'far':!item.wishList}" @click="wishList()"></i>
                
                <span class="ticket bg-burgundy-1 text-white px-3 py-2 fw-bold cursor-pointer" v-if="item.status" @click="ClickCheck(item)">BUY TICKET</span>
                <span class="ticket bg-secondary fs-px-12 text-white px-3 py-2 fw-bold cursor-pointer" v-else>CLOSED</span>
            </div>
        </div>
        <PurchasePopup :show="modalShow" :info="item" @close="modalClose" v-if="modalShow"/>
    </li>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import PurchasePopup from '@/components/deal/PurchaseModal.vue'
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');

export default {
    components:{
        BAvatar, PurchasePopup
    },
    props:{
        item: {
            type: Object,
            default: {
                idx:0,
                userType:'admin',
                stop_selling:false,
                title:'',
                avatar:'',
                seller:'',
                coin:'',
                price:0,
                total:0,
                participate:0,
                totalNo:0,
                d_price : 0,
                wishList:false,
                expiryDate:new Date(),
            },
        },
        index: {
            type: Number,
            default: null,
        },
        hot_deal: false
    },
    data(){
        return{
            modalShow:false
        }
    },
    computed:{
        status(){
            const now = new Date();
            const expiryDate = new Date(this.item.expiryDate);

            const timeDiff = expiryDate.getTime() - now.getTime();

            if (timeDiff <= 0) {
                return false
            }else if(this.item.stop_selling){
                return false;
            }else if(this.item.participate===this.item.total){
                return false;
            }else{
                return true;
            }
        }
    },
    methods:{
        ClickCheck(item){
            if(item.my_deal =='Y'){
                this.$router.push({path:`/deal/detail/${item.code}`})
            }else{
                this.modalShow = !this.modalShow
            }
        },
        GotoSeller(item){
            const seller_code = item.seller_code;
            const m_code = item.m_code;
            
            if(seller_code !=''){
                if(m_code !=seller_code){
                    this.$router.push(`/landing/${item.seller_code}`)
                }else{
                    this.$router.push(`/mypage`)
                }
            }
            
        },
        CheckPercentBar(item){
            if(item.count ==0){
                return 0
            }else{
                const bar = (item.count/item.total)*100;
                const b_per = bar.toFixed(1)
                return b_per
            }
            
        },
        CheckPercent(a,b){
            const count = a;
            const total = b;
            const m = exactMath.div(count,total);
            const per = exactMath.mul(m,100);

            let percent = parseInt(per);

            if(count > 0){
                if(percent <1){
                    percent = 1
                }
            }
            
            return percent;
            

        },
        goTodetail(){
            this.$router.push({
                name: 'DealDetail',
                params: {
                    idx: this.item.code,
                    item: this.item
                }
            })
        },
        modalClose() {
            this.modalShow = false;
        },
        
        wishList(){
            if(this.item.status ==false){
                this.$pp({
                    msg:'This wish list has already been closed.',
                    auto_hide:true
                })
                return false;
            }
            if(this.item.wishList){
                
                this.DeleteWishList()
            }else{
                
                this.AddWishList();
            }
            this.item.wishList=!this.item.wishList;

            
        },
        AddWishList(){
            const room_code = this.item.code;
            const body = {room_code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/common/AddWishList',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        if(res.data.code =="200"){
                            this.$pp({
                                msg:'Added on wish list',
                                auto_hide:true
                            })
                        }else{
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                        
                    }else{
                        this.$store.dispatch('logout').then(
                            ()=>{
                            location.href = `/signin`;
                            }
                        );
                    }
                }
            );
        },
        DeleteWishList(){
            const room_code = this.item.code;
            const body = {room_code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/common/DeleteWishList',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        if(res.data.code =="200"){
                            this.$pp({
                                msg:'Removed from wish list',
                                auto_hide:true
                            })
                        }else{
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                        
                    }else{
                        this.$store.dispatch('logout').then(
                            ()=>{
                            location.href = `/signin`;
                            }
                        );
                    }
                }
            )
        }
    },
}
</script>