<template>
    <footer class="p-2 text-center text-white">
        <div class="flex-center-center text-yellow-1 lh-1 fw-400 mt-1" v-if="$route.path == '/'">
            <span  class="pointer" @click="TermsPopupOn(0)">Terms of service</span>
            <span class="mx-3 bg-yellow-1 h-px-10 w-px-1">　</span>
            <span  class="pointer" @click="TermsPopupOn(1)">Privacy policy</span>
        </div>
        <p class="mb-1 mt-1"><a href="https://onecoinbuy.gitbook.io/onecoinbuy-whitepaper" target="_new"><span class="text-yellow-1 fw-400">OBC Token Whitepaper</span></a><br></p>
        <p class="small mb-1 mt-1">contact@onecoinbuy.com<br></p>
        COPYRIGHT ALL RIGHTS RESERVED OCB Enterprise
        <ul class="pt-2 d-flex justify-content-center gap-4 fs-2">
            <li class="pointer"><a href="https://t.me/onecoinbuy_official" target="_new"><i class="fab fa-telegram-plane"></i></a></li>
            <li class="pointer"><a href="https://twitter.com/official_ocb" target="_new"><i class="fab fa-twitter"></i></a></li>
        </ul>

        <TermsPopup ref="TermsPopup" />
    </footer>
</template>

<script>
import TermsPopup from '@/components/terms/Terms.vue'

export default {
    components:{
        TermsPopup
    },
    methods:{
        TermsPopupOn(idx){
            this.$refs.TermsPopup.idx = idx;
            this.$refs.TermsPopup.pp = true;
        }
    }

}
</script>

<style lang="scss" scoped>
footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    font-weight: 100;
    font-size: .8rem;
    background-color: #151515;
}

.pointer {
    cursor: pointer;
}
</style>